import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading } from "../../../packages/Typography/src";
import DataStoryCard from "../../../packages/DataStoryCard/src";
import { DATASTORIES } from "../../constants/patterns";

var DataStoryListingPattern = function DataStoryListingPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "md",
    mb: "5rem"
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Small Data Story cards"), React.createElement(Grid, null, DATASTORIES.slice(0, 3).map(function (_ref) {
    var id = _ref.id,
        restProps = _objectWithoutPropertiesLoose(_ref, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", "span 4"]
    }, React.createElement(DataStoryCard, Object.assign({
      size: "sm"
    }, restProps)));
  }))), React.createElement(Container, {
    size: "md",
    mb: "5rem"
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Medium Data Story cards"), React.createElement(Grid, null, DATASTORIES.slice(0, 8).map(function (_ref2) {
    var id = _ref2.id,
        restProps = _objectWithoutPropertiesLoose(_ref2, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", "span 6", "span 3"]
    }, React.createElement(DataStoryCard, restProps));
  }))), React.createElement(Container, {
    size: "md"
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Large Data Story cards"), React.createElement(Grid, null, DATASTORIES.slice(0, 2).map(function (_ref3) {
    var id = _ref3.id,
        restProps = _objectWithoutPropertiesLoose(_ref3, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", "span 6"]
    }, React.createElement(DataStoryCard, Object.assign({
      size: "lg"
    }, restProps)));
  }))));
};

export default DataStoryListingPattern;