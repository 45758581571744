export var BLOG_POSTS = [{
  id: "DatoCmsStory-1097989-sv",
  seo: {
    title: "The winners – Datastory Summer Challenge ",
    description: "The Datastory Summer Challenge received applications from all around the world on  themes ranging from trade to environment to elderly care. ",
    twitterCard: "summary_large_image",
    image: {
      url: "https://www.datocms-assets.com/5436/1560344453-story-cover.png",
      createdAt: "2019-06-12T13:00:55.145Z"
    }
  },
  authors: [{
    name: "Daniel Lapidus"
  }, {
    name: "Elsa Jensen"
  }, {
    name: "Gunilla Hagström"
  }]
}, {
  id: "DatoCmsStory-970177-sv",
  seo: {
    title: "Inspiration and tips for the Datastory Summer Challenge ",
    description: "The team answers some of the most frequently questions ",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1557829520-ds-bg-faq.png",
      createdAt: "2019-05-14T10:25:27.083Z"
    }
  },
  authors: [{
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-499281-sv",
  seo: {
    title: "Datastory lanserar skoltema",
    description: "Hur jämlik är den svenska skolan? Varför klarar sig vissa skolor så mycket bättre än andra år efter år?",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1557081554-skoltema.png",
      createdAt: "2018-11-27T14:04:31.438Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }, {
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-808889-sv",
  seo: {
    title: "Vi delar med oss av kod och analys",
    description: "Nu delar vi med oss av all kod som producerar respektive graf, från inhämtning av data till visualisering.",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1554466242-notebook-screenshot.png",
      createdAt: "2019-04-04T11:41:55.780Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }]
}, {
  id: "DatoCmsStory-467405-sv",
  seo: {
    title: "Nya visualiseringar om svensk väljaropinion",
    description: "I dag publicerar Datastory och Valforskningsprogrammet gemensamt ett nytt interaktivt projekt om den svenska väljaropinionen",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1544189453-nya-vis.png",
      createdAt: "2018-11-19T10:36:07.852Z"
    }
  },
  authors: [{
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-575341-sv",
  seo: {
    title: "Fem nyheter på Datastory.org",
    description: 'Vi introducerar "Hur ofta uppdateras applikationen", ämnessidor, förslagslåda med mera.',
    twitterCard: "summary_large_image",
    image: {
      url: "https://www.datocms-assets.com/5436/1544525045-5-news.png",
      createdAt: "2018-12-11T10:44:09.261Z"
    }
  },
  authors: [{
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-591605-sv",
  seo: {
    title: "Årskrönika nyhetsåret 2018",
    description: "Vi följer upp vår mediegranskning och tittar på hur svenska mediers utrikesbevakning såg ut under året som gått",
    twitterCard: "summary_large_image",
    image: {
      url: "https://www.datocms-assets.com/5436/1545142844-year-in-review-1200x675.png",
      createdAt: "2018-12-13T16:02:54.933Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }]
}, {
  id: "DatoCmsStory-467406-sv",
  seo: {
    title: "Vad vi lärde oss på Openvis Conf Paris",
    description: "Förra veckan reste delar av Datastory-teamet — Daniel, Arek, Jialing och undertecknad —till årets upplaga av Openvis Conf, en konferens om datavisualisering som för första gången lämnade USA och hölls i Paris.",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1542624495-image.png",
      createdAt: "2018-11-19T10:48:17.993Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }]
}, {
  id: "DatoCmsStory-582412-sv",
  seo: {
    title: "Unik kartläggning — exempel från tre världsdelar",
    description: "Hur skriver svenska medier om Nicaragua, Indien och Moçambique ",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1544783798-cover-1200-675.jpg",
      createdAt: "2018-12-14T10:23:44.811Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }, {
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-467285-sv",
  seo: {
    title: "Här kommer Datastory",
    description: "Svett, tårar och statistik. Efter intensivt kodknackande, oräkneliga designrundor och mycket skrivande är vi glada över att kunna lansera",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1542970292-cjotwo9hd0001wartmj7ozbyjimage.png",
      createdAt: "2018-11-23T10:51:34.948Z"
    }
  },
  authors: [{
    name: "Daniel Lapidus"
  }]
}, {
  id: "DatoCmsStory-995260-en",
  seo: {
    title: "Mapping the EU – voter turnout district by district.",
    description: "By combining data from all European countries, our new maps reveal differences between countries and distinct regional patterns. ",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1558684910-eu-turnout-cover.png",
      createdAt: "2019-05-24T08:01:57.903Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }]
}, {
  id: "DatoCmsStory-995260-sv",
  seo: {
    title: "Mapping the EU – voter turnout district by district.",
    description: "By combining data from all European countries, our new maps reveal differences between countries and distinct regional patterns. ",
    twitterCard: null,
    image: {
      url: "https://www.datocms-assets.com/5436/1558684910-eu-turnout-cover.png",
      createdAt: "2019-05-24T08:01:57.903Z"
    }
  },
  authors: [{
    name: "Robin Linderborg"
  }]
}];
export var DATASTORIES = [{
  id: "DatoCmsApp-301249-sv",
  title: "Hypotetisk riksdag",
  description: "Hur skulle riksdagen se ut om en viss väljargrupp röstade?",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-417467",
    url: "https://www.datocms-assets.com/5436/1554104598-hyporikstag-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1543327930-hyporiksdag-2.svg"
  }
}, {
  id: "DatoCmsApp-301250-sv",
  title: "Regeringsalternativen",
  description: "Hur de 349 mandaten fördelas just nu",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-417502",
    url: "https://www.datocms-assets.com/5436/1554103735-coalitions-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1542296500-regeringsalternativ.svg"
  }
}, {
  id: "DatoCmsApp-301251-sv",
  title: "Ett lyckligt äktenskap?",
  description: "Bygg din egen regering utifrån opinionsläget",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-417470",
    url: "https://www.datocms-assets.com/5436/1554104528-happy-marriage-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1542296473-happymarriage.svg"
  }
}, {
  id: "DatoCmsApp-308700-en",
  title: "Poll Of Polls",
  description: "Poll Of Polls",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-414107",
    url: "https://www.datocms-assets.com/5436/1542625536-image.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1545227751-pollofpolls.svg"
  }
}, {
  id: "DatoCmsApp-308700-sv",
  title: "Mätningarnas mätning",
  description: "Väger samman de viktigaste opinionsmätningarna",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-417461",
    url: "https://www.datocms-assets.com/5436/1554104558-polls-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1545227751-pollofpolls.svg"
  }
}, {
  id: "DatoCmsApp-584957-sv",
  title: "Vita fläckar på mediekartan",
  description: "Hur världen ser ut genom svenska mediers rapportering",
  twitterCard: null,
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-450550",
    url: "https://www.datocms-assets.com/5436/1544783249-cjpnw27ba00074exbuqjp93qgimage.png"
  },
  logo: null
}, {
  id: "DatoCmsApp-584182-sv",
  title: "Datastorys nyhetsindex",
  description: "Hur svenska medier bevakar världen. Uppdateras varje dag.",
  twitterCard: "summary_large_image",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-462762",
    url: "https://www.datocms-assets.com/5436/1554104490-newsindex-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1545225757-icon-blank-spot-index-1-1.svg"
  }
}, {
  id: "DatoCmsApp-466660-sv",
  title: "Hur ojämlik är din skolkommun?",
  description: "Överblicka resultaten i svensk grundskola.",
  twitterCard: null,
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-420759",
    url: "https://www.datocms-assets.com/5436/1554707338-rotato-school-scatterplot-3.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1543321179-equalschool.svg"
  }
}, {
  id: "DatoCmsApp-429387-sv",
  title: "Den politiska kartan",
  description: "Så rör sig väljarna över den politiska kartan",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-431943",
    url: "https://www.datocms-assets.com/5436/1554104624-political-map-cover.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1543309607-issuesmap.svg"
  }
}, {
  id: "DatoCmsApp-585206-sv",
  title: "Datastorys nyhetsindex",
  description: "Lorem ipsum",
  twitterCard: null,
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-450550",
    url: "https://www.datocms-assets.com/5436/1544783249-cjpnw27ba00074exbuqjp93qgimage.png"
  },
  logo: null
}, {
  id: "DatoCmsApp-437080-sv",
  title: "Skolkartan",
  description: "Lorem ipsum ipsum\n",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-420761",
    url: "https://www.datocms-assets.com/5436/1557081554-skoltema.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1543327428-schoolmap.svg"
  }
}, {
  id: "DatoCmsApp-308693-sv",
  title: "De 349 ledamöterna",
  description: "Lär känna riksdagsledamöterna genom deras förslag",
  twitterCard: "summary",
  updated: "Updated daily",
  image: {
    id: "DatoCmsAsset-417451",
    url: "https://www.datocms-assets.com/5436/1542895336-349.png"
  },
  logo: {
    url: "https://www.datocms-assets.com/5436/1542296548-ledamoter.svg"
  }
}];
export var TOPICS_DATA = [{
  id: "DatoCmsTopic-464840-sv",
  color: {
    hex: "#ff5419"
  },
  iconSquare: {
    url: "https://www.datocms-assets.com/5436/1554380960-swedish-schools-square.svg"
  },
  seo: {
    title: "Skola och utbildning",
    description: "Datastory visualiserar öppna data och annan information om den svenska skolan för att skapa bättre möjligheter till faktabaserade beslut.",
    twitterCard: null
  }
}, {
  id: "DatoCmsTopic-309016-sv",
  color: {
    hex: "#dd1a08"
  },
  iconSquare: {
    url: "https://www.datocms-assets.com/5436/1554380159-topic-illustration-square.svg"
  },
  seo: {
    title: "Svensk politik",
    description: "Förstå svensk politik på djupet med hjälp av statistik om partierna, kandidaterna och sakfrågorna.\n\n\n",
    twitterCard: "summary"
  }
}, {
  id: "DatoCmsTopic-513817-sv",
  color: {
    hex: "#2d2872"
  },
  iconSquare: {
    url: "https://www.datocms-assets.com/5436/1554450171-media-illustration-square.svg"
  },
  seo: {
    title: "Svenska medier",
    description: "Med hjälp av små och stora dataset belyser Datastory hur svenska medier skildrar olika ämnen och länder.",
    twitterCard: null
  }
}, {
  id: "DatoCmsTopic-361287-en",
  color: {
    hex: "#008fd4"
  },
  iconSquare: {
    url: "https://www.datocms-assets.com/5436/1542291141-global-development-topic-card.svg"
  },
  seo: {
    title: "Global Development",
    description: "Världen förändras i rask takt. Använd Datastorys verktyg för att förstå utvecklingen på djupet.",
    twitterCard: "summary"
  }
}, {
  id: "DatoCmsTopic-361287-sv",
  color: {
    hex: "#008fd4"
  },
  iconSquare: {
    url: "https://www.datocms-assets.com/5436/1542291141-global-development-topic-card.svg"
  },
  seo: {
    title: "Global utveckling",
    description: "Världen förändras i rask takt. Använd Datastorys verktyg för att förstå utvecklingen på djupet.",
    twitterCard: "summary"
  }
}];